<template>
    <home-two/>
</template>

<script>
import HomeTwo from '../components/HomeTwo/index'
export default {
	components: {HomeTwo  },
    metaInfo: {
        title: 'Roftr Clouds LLP',
        titleTemplate: '%s | Product Development and Customization, Software development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Roftr Clouds LLP is one among the best Website and Software development company in Coimbatore, helps you get quality and long-term business solution provider in IT Company.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link: [
            { rel: 'alternate', href: 'www.roftr.com', hreflang: 'en-in'}
        ]
    }

}
</script>

<style>

</style>