<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <PP-Title-Area/>
      
    <section class="appie-service-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                   <body-nav-sidebar/>
                </div>
                <div class="col-lg-8">
                    <div class="service-details-content">
                      
                        <div class="content">
                           <h3 class="title">Privacy Policy</h3>
                               <p>Thank you for choosing to be part of our community at Roftr Clouds LLP ("Roftr," "we," "us," or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at support@roftr.com</p>
                                <br />
                               <p>This privacy notice describes how we might use your information if you:</p>
                               <p>In this privacy notice, if we refer to:</p>
                               <p>The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</p>
                                <br />
                               <p><strong>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</strong></p>
                            <br />
                            
                            <h5>What information do we Collect?</h5><br>
                                <p><strong>Personal information you disclose to us</strong></p><br>
                                <p><em>In Short:  We collect personal information that you provide to us.</em></p>
                            <br />
                                <p>We collect personal information that you voluntarily provide to us when you use "Contact Us Form" (Name, Mobile and Email id) on the express an interest in obtaining information about us or our products and Services, when you participate in activities on the (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</p>
                            <br />
                                <p>The personal information that we collect depends on the context of your interactions with us and the , the choices you make and the products and features you use. The personal information we collect may include the following: Name, Mobile and E-Mail id.</p>
                            <br />
                                <p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
                            <br />
                            
                            <h5>Information automatically collected</h5>
                            <br />
                                <p><em>In Short:  Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics - is collected automatically when you visit our pages.</em></p>
                                    <br />
                                <p>We automatically collect certain information when you visit, use or navigate the pages. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.</p>
                                <br />
                                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                                <br />
                                <p></p>
                        
                            
                             <h5>Will your information be shared with anyone?</h5>
                            <br />
                                <p><em>In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em></p>
                                <br />
                                <p>We may process or share your data that we hold based on the following legal basis:</p>
                                
                                <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
                                <br />
                                <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>
                                <br />
                                <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>
                                <br />
                                <p>When you share personal information or otherwise interact with public areas, such personal information may be viewed by all users and may be publicly made available outside the in perpetuity. If you interact with other users of our and register for our through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our , and view your profile.</p>
                                <br />
                             
                             <h5>Do we use cookies and other tracking technologies?</h5>
                            <br />
                                <p><em>In Short:  We may use cookies and other tracking technologies (Google Analytics) to collect and store your information.</em></p>
                                <br />
                                <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
                                <br />
                                
                                
                             <h5>How long do we keep your information?</h5>
                            <br />
                                <p><em>In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></p>
                                <br />
                                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 90 days.</p>
                                <br />
                                <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                                <br />
                              
                               
                        
                         <h5>Do we collect information from minors?</h5>
                            <br />
                                <p><em>In Short:  We do not knowingly collect data from or market to children under 18 years of age.</em></p>
                                <br />
                                <p>We do not knowingly solicit data from or market to children under 18 years of age. By using Career page, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the page. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support@roftr.com .</p>
                                <br />
                                
                                
                          <h5>Controls for do-not-track features</h5>
                                <br />
                                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. </p>
                                <br />
                                <p>As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>
                                <br />
                                
                        
                        
                        <h5>Do we make updates to this notice?</h5>
                                <br />
                                <p><em>In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></p>
                                <br />
                                <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                                <br />
                               
                        
                        
                        
                        <h5>How can you contact us about this notice?  </h5>
                                <br />
                                <p>If you have questions or comments about this notice, you may email us at support@roftr.com </p>
                                <br />
                               
                        
                        
                        
                        <h5>How can you review, update, or delete the data we collect from you?</h5>
                                <br />
                                <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you (Career and Contact Pages), change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by <router-link to="/contact-us">clicking here.</router-link></p>
                                <br />
                                
                        
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
   
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 
<script>

import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import PPTitleArea from './PPTitleArea.vue'
import GetStarted from '../Footer/GetStarted.vue'
import BodyNavSidebar from './BodyNavSidebar.vue';

export default {
	components: { SidebarHomeTwo, PPTitleArea, GetStarted, HeaderProduct, FooterHomeTwo, BodyNavSidebar },
        data(){
        return{
            sidebar:false,
            
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                // {
                //     name:'Zoho',
                //     path:'/zoho'            
                // },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
            //    {
            //         name:'services',
            //         path:'/services'
            //     },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>