<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <TandA-Title-Area/>
      
    <section class="appie-service-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                   <body-nav-sidebar/>
                </div>
                <div class="col-lg-8">
                    <div class="service-details-content">
                      
                        <div class="content">
                            <h3 class="title">Cancellation Policy</h3>
                            <span>Thank you for choosing our Roftr Clouds LLP software. We aim to provide flexibility and transparency in managing your subscription. Please review our cancellation policy carefully to understand your rights and obligations regarding subscription cancellations.</span>
                            <br />                            
                            <h5>1. Subscription Cancellation</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>You can cancel your subscription at any time through your account settings or by contacting our support team.</li>
                                    <li>Cancellations take effect at the end of the current billing cycle. You will retain access to the software until the cycle ends.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>2. No Prorated Refunds</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>We do not provide prorated refunds for partial months or unused subscription periods. Access to the software remains active until the billing cycle concludes.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>3. Free Trial Cancellation</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>If you are within a free trial period, you can cancel your subscription without incurring any charges.</li>
                                    <li>Failure to cancel before the trial period ends will result in automatic billing based on your selected subscription plan.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>4. Auto-Renewal</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>Subscriptions are set to renew automatically at the end of each billing cycle unless canceled prior to the renewal date.</li>
                                    <li>Notifications regarding upcoming renewals are sent in advance to ensure transparency.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>5. How to Cancel</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>Log in to your account and navigate to the subscription settings to initiate a cancellation.</li>
                                    <li>Alternatively, contact our support team at  support@roftr.com with your account details for assistance.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>6. Re-Activation of Subscription</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>You can reactivate a canceled subscription at any time by logging into your account and selecting a plan.</li>
                                    <li>Reactivated subscriptions are subject to current pricing and terms.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>7. Changes to the Cancellation Policy</h5><br>
                            <p>
                                We reserve the right to update or modify this cancellation policy at any time. Changes will be effective immediately upon posting on our website. <br>
                                For further assistance or inquiries, please contact our support team at support@roftr.com
                            </p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 <style>
ul.custom_bullet {
list-style-type: disc;
list-style-position: inside;
}
</style>
<script>

import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import TandATitleArea from './TandATitleArea.vue'
import GetStarted from '../Footer/GetStarted.vue'
import BodyNavSidebar from './BodyNavSidebar.vue';

export default {
	components: { SidebarHomeTwo, TandATitleArea, GetStarted, HeaderProduct, FooterHomeTwo, BodyNavSidebar },
        data(){
        return{
            sidebar:false,
            
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                // {
                //     name:'Zoho',
                //     path:'/zoho'            
                // },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>