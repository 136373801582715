<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <TandA-Title-Area/>
      
    <section class="appie-service-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                   <body-nav-sidebar/>
                </div>
                <div class="col-lg-8">
                    <div class="service-details-content">
                      
                        <div class="content">
                            <h3 class="title">Terms and Conditions</h3>
                            <span>In these Terms and Conditions "we, our, us, refers to Roftr Clouds LLP . "You" and "Client" refers to Roftrs' Client or buyer of any product or service offered by Roftr Clouds LLP.</span>
                            <br />                            
                            <h5>Billing/Credit Card charging</h5><br>
                            <p>We accept the payments through wire-transfer (State Bank of India, ICICIBANK, City Union Bank), Pay Pal or client convenient way of paying.  Roftr Clouds LLP will never bill you with hidden or undisclosed charges at any time. All the invoices and payment requests are generated only when the client has previously agreed to pay that particular amount.</p>
                            <br />
                            <h5>Copyrights and Trade marks</h5><br>
                            <p>Client unconditionally guarantees that any elements of text, graphics, photos, designs, trademarks, or other artwork furnished to Roftr Clouds LLP for inclusion in the Project are owned by Client, or that Client has permission from the rightful owner to use each of these elements, and will hold harmless, protect, indemnify and defend Roftr Clouds LLP and its sub-contractors from any liability (including legal fees and court costs), including any claim or suit, threatened or actual, arising from the use of such elements furnished by Client.</p>
                            <br />
                            <h5>Abuse Through Content</h5><br>
                            <p>The content/idea/theme of a web site is provided by client. Roftr Clouds LLP can just help you in actual conceiving the idea. You agree that you will not post any material which is false, defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented, threatening, invasive of a person's privacy, or otherwise in violation of ANY law.</p>
                            <br />
                            <h5>Completion Date</h5><br>
                            <p>Roftr Clouds LLP and Client undertake to work together to complete Project within the timescale defined in the specification. Roftr Clouds LLP  will inform client on project progress and will always endeavor to meet the defined milestones. Client must also supply all source materials to timescale. Customized projects may not have deadline, due to new additions.</p>
                            <br />
                            <h5>Delivery of Website</h5><br>
                            <p>Uploading a web site to your Hosting account, sending files through FTP, CD or any other media is considered as delivery of web site after which the client is not eligible for a refund. By signing up with Roftr Clouds LLP, the client agrees that he/she will clear any balance dues after the web site has been delivered.</p>
                            <br />
                            <h5>Limitation of Liability</h5><br>
                            <p>Roftr Clouds LLP will under no circumstance be liable for indirect, special, or consequential damages including any loss of business, revenue, profits, or data in relation to your use of the Web site.</p>
                            <br />
                            <h5>Service and Product Rates</h5><br>
                            <p>All Client Order's require an advance payment of a minimum of 50% before the implementation as confirmation of Project and remaining payment should be paid on Project completion and not related to Application launch. As soon as web application launch, we will provide long term support.</p>
                            <br />
                            <h5>Acceptance</h5><br>
                            <p>Where Web site design or other Programming Services are supplied, Customer will notify Roftr Clouds LLP of any errors or omissions for rectification within a period of 7 days from Delivery. After 7 days from Delivery, Customer is deemed to have accepted that the Delivery is provided to an acceptable level of quality.</p>
                            <br />
                            <h5>Commencement</h5><br>
                            <p>Roftr Clouds LLP cannot always guarantee to start work on web design projects immediately. If this is the case it will be discussed with the client before any work is started. All reasonable efforts will be made to work to client time scales.</p>
                            <br />
                            <h5>Backups and Data Loss</h5><br>
                            <p>Your use of the service is at your sole risk. We are not responsible for files and data residing on your account. You agree to take full responsibility for files and data transferred and to maintain all appropriate backup of files and data stored on your servers.</p>
                            <br />
                            <p>Moreover we will have complete software backup that can be restored on your server upon your request. We help you to take back up for every month.</p>
                            <br />
                            <span>If you have any questions or comments please e-mail us any time at: support@roftr.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 
<script>

import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import TandATitleArea from './TandATitleArea.vue'
import GetStarted from '../Footer/GetStarted.vue'
import BodyNavSidebar from './BodyNavSidebar.vue';

export default {
	components: { SidebarHomeTwo, TandATitleArea, GetStarted, HeaderProduct, FooterHomeTwo, BodyNavSidebar },
        data(){
        return{
            sidebar:false,
            
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                // {
                //     name:'Zoho',
                //     path:'/zoho'            
                // },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'services',
                //     path:'/services'
                // },
                // {
                //     name:'Pricing',
                //     path:'/pricing'
                // },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>