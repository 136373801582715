<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />

    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <title-area/>
    
    <we-offer/>
    <ecom-features-plans/>

    <ecom-plans-pricing title="Choose your eCom Application" description="Need to try only for few months to know your online sales, we have rental plan too."/>

    <features/>
    
    <faq class="pb-95 pt-90" title="Frequently asked questions" description="Clarify everything about E-Commerce application."/>
    

    <our-clients title="Our Clients" more_title="" description="Best Investors for the growth of our Product."/>

    <get-started/>

    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    

    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 
<script>
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg"
import SidebarHomeTwo from '../../Sidebar/SidebarHomeTwo.vue'
import HeaderProduct from '../../Header/HeaderProduct.vue'
import TitleArea from './TitleArea.vue'
import WeOffer from './WeOffer.vue'
import EcomFeaturesPlans from './EcomFeaturesPlans.vue'
import EcomPlansPricing from './EcomPlansPricing.vue'
import Features from './Features.vue'
import Faq from './Faq.vue'
import OurClients from './OurClients.vue'
import GetStarted from '../../Footer/GetStarted.vue'
import FooterHomeTwo from '../../Footer/FooterHomeTwo.vue'


export default {
	components: { SidebarHomeTwo, TitleArea, Features, Faq, OurClients, EcomPlansPricing, GetStarted, HeaderProduct, EcomFeaturesPlans, WeOffer, FooterHomeTwo },
        data(){
        return{
            sidebar:false,
            video_bg_thumb:video_bg_thumb,
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                // {
                //     name:'Zoho',
                //     path:'/zoho'            
                // },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>